import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Checkbox, Select, Divider, Row, Col, Modal } from 'antd';
import { RegisterContainer,FlexContainer, ModalContainer } from './style';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {signUp,checkUnique,verifySignUp,resendRegOtp} from '../../action/registrationAction'
import OtpInput from 'react-otp-input';
import Countdown from 'antd/lib/statistic/Countdown';
import history from '../../history';
const { Option } = Select;
const Registration = ({signUp,checkUnique,verifySignUp,resendRegOtp}) => {
    const [isUnique, setIsUnique] = useState(true)
    const [resend, setResend] = useState(false)
    const [show, setShow] = useState(false)
    const [lan, setLan] = useState('english');
    const [val, setVal] = useState('');
    const [submit, setSubmit] = useState(false)
    const [mobile, setMobile] = useState('')

    const [form] = Form.useForm();
    useEffect(() => {
        setLan(localStorage.getItem('lan'))
        
        window.scrollTo(0, 0);
        // form.setFieldsValue('country_code')
        form.setFieldsValue({
            country_code: "88",
          });
      }, [])
      
      const onFinish = async (values) => {
        const code = await signUp(values)
        
        if(code !== undefined || code === 201 ){
            setShow(!show)
        }
      };
    
      const onFinishFailed = (errorInfo) => {
      };

      const handleNumberCheck = async (e) => {
        setMobile(e.target.value)
        if(e.target.value.length == 11){

            const res = await checkUnique(e.target.value)
            setIsUnique(res)
        }
      }

      const setOTP = async (e) => {
        setVal(e)
        if(e.length > 3 ){
            setSubmit(true)
        }
        else{
            setSubmit(false)
        }
      }
      const submitOtp = async (e) => {
        const res = verifySignUp(mobile, val)
        setVal('')
      }      

      const handleResendOTP = async () => {
       const res = await resendRegOtp(mobile)
       setVal('')
       
       setResend(false)
      }
      
    const deadline = Date.now() + 1000 * 120;
    return ( 
        <FlexContainer>
            <RegisterContainer>
                    <img src='./images/logo.png' />
                    <h1>{lan == 'bangla' ? "মার্চেন্ট হিসেবে রেজিষ্ট্রেশন করুন" : "Register as Merchant"}</h1>
                    <p>{lan == 'bangla' ? "আপনার ইনফরমেশন সঠিক ভাবে পূরণ করুন" : "Input your information and create merchant account"}</p>
                    <Form
                    name="basic"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    style={{width: '100%'}}
                    requiredMark={false}
                    form={form}
                    >
                        
                        <Form.Item name="country_code" hidden={true} defaultValue="+88"
                        rules={[
                            {
                                required: true,
                                message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                            }
                        ]}>
                            <Input value="88" defaultValue="+88" />
                        </Form.Item>
                        <br />
                        <br />
                        <Row>
                            <Col sm={24}>
                                <Form.Item
                                    label={lan == 'bangla' ? "নাম" : "Name"}
                                    name="name"
                                    rules={[
                                    {
                                        required: true,
                                        message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                    },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col sm={24}>
                                <Form.Item
                                    label={(isUnique || isUnique === undefined) ? lan == 'bangla' ? "মোবাইল নাম্বার" : "Mobile number" :<span style={{color: '#ED1C24'}}> {lan == 'bangla' ? "এই নম্বরটি ইতোমধ্যে ব্যবহার করা হয়ে গিয়েছে" : "This number already in use"}</span> }
                                    name="mobile_number"
                                    onChange={(e) => handleNumberCheck(e)}
                                    rules={[
                                    {
                                        required: true,
                                        message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                    },
                                    {
                                        pattern: new RegExp(/^(01)[0-9][0-9]{8}$/),
                                        message: lan == 'bangla' ? "মোবাইল নাম্বারটি সঠিন নয়" : "Mobile Number is not valid",
                                    }
                                    ]}
                                >
                                    <Input prefix="+88 " />
                                </Form.Item>
                            </Col>
                            <Col sm={24}>
                                <Form.Item
                                    label={lan == 'bangla' ? "ইমেইল" : "Email"}
                                    name="email"
                                    rules={[
                                    {
                                        required: true,
                                        message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                    },
                                    {
                                        pattern: new RegExp(/\S+@\S+\.\S+/),
                                        message: lan == 'bangla' ? "ইমেইলটি সঠিন নয়" : "Email is not valid",
                                    }
                                    ]}
                                >
                                    <Input 
                                    type="email" />
                                </Form.Item>
                            </Col>
                            

                             
                            <Col sm={24}> 
                                <Form.Item
                                    label={lan == 'bangla' ? "পাসওয়ার্ড" : "Password"}
                                    name="password"
                                    rules={[
                                    {
                                        required: true,
                                        message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                    },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                            <Col sm={24}>
                                <Form.Item
                                    label={lan == 'bangla' ? "পাসওয়ার্ডটি আবার লিখুন" : "Confirm Password"}
                                    name="confirm_password"
                                    rules={[
                                    {
                                        required: true,
                                        message: lan == 'bangla' ? "অত্যাবশ্যকীয়" : "This field is required",
                                    },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>

                            <Col sm={24}>
                                
                                <Form.Item name="agreement" valuePropName="checked" wrapperCol={{ offset: 1, span: 23 }} rules={[
                                    {
                                        required: true,
                                        message: 'Without accepting the terms and condition you can not register',
                                    },
                                    ]}>
                                    <Checkbox>{lan == 'bangla' ? "আমি সকল প্রকার টার্ম এবং কন্ডিশন মেনে নিয়েছি" : "I accept the terms and conditions"}</Checkbox>
                               
                                </Form.Item>
                               
                            </Col>
                   

                            
                        </Row>
                    
                    


                    <Form.Item
                    style={{textAlign: 'center'}}
                    >
                        {isUnique === false ?
                        <Button type="primary" htmlType="submit" disabled> {lan == 'bangla' ? "রেজিষ্ট্রেশন করুন" : "Register"} </Button>
                        :
                        <Button type="primary" htmlType="submit" > {lan == 'bangla' ? "রেজিষ্ট্রেশন করুন" : "Register"} </Button>
                        }
                    </Form.Item>
                    <p>{lan == 'bangla' ? "আপনার একাউন্ট রয়েছে ?" : "Already have an account?"}<Link to="/login"> {lan == 'bangla' ? "লগ ইন করুন" : "Login"}</Link></p>
                    
                    <p><a href='/terms' target="_blank">Our terms and conditions</a></p>
                    </Form>
            </RegisterContainer>


            <Modal title={lan == 'bangla' ? 'ওটিপি ভেরিফিকেশন' : 'OTP Verification'} visible={show} onOk={() => setShow(false)} onCancel={() => setShow(false)}>
                <ModalContainer>
                    <img src="./images/logo.png" />
                    <h2>{lan == 'bangla' ? 'একাউন্ট ভেরিফাই করুন' : 'Verify your account'}</h2>
                    <p>
                        {lan == 'bangla' ? 'আপনার মোবাইলে আসা ৪ ডিজিট এর ওটিপি কোডটি ব্যবহার করুন' : 'Please Check your mobile sms to take 4 digit OTP code for continue'}
                    </p>
                    <div  style={{alignSelf: 'center'}}>
                        
                        <OtpInput
                            shouldAutoFocus={true}
                            isInputNum={true}
                            value={val}
                            onChange={(otp) => setOTP(otp)}
                            numInputs={4}
                            separator={<span>-</span>}
                            inputStyle={{width: '60px', height: '60px', margin: '0px 10px', fontSize: '18px', border: '1px solid Gray'}}
                        />
                        
                    </div>
                    <br />
                    <br />
                    {resend == true ? 
                            <Button type="primary" htmlType="submit" onClick={() =>handleResendOTP() }>
                                {lan == 'bangla' ? 'পুনরায় কোডটি পাঠান' : 'Resend OTP'}
                            </Button>
                        :
                        <Countdown style={{textAlign: 'center'}} title={lan == 'bangla' ? 'পুনরায় কোডটি পাঠান' : 'Resend'} size="small" value={deadline} onFinish={() => setResend(true)} /> }
                       <br /> 
                        
                    {submit ? <Button type='primary' onClick={submitOtp}>{lan == 'bangla' ? 'সাবমিট করুন' : 'Submit'}</Button>
                            : <Button type='primary' disabled onClick={submitOtp}>{lan == 'bangla' ? 'সাবমিট করুন' : 'Submit'}</Button>}
                               
                   
                          
                          
                            
                </ModalContainer>
            </Modal>

        </FlexContainer>
     );
}
 
export default connect(null,{signUp,checkUnique,verifySignUp,resendRegOtp})(Registration);