
import { Avatar, Badge, Button, Col, Dropdown, Menu, Comment, Tooltip, Tag } from 'antd';
import React, { useEffect, useState } from 'react'
import history from '../../history';
import { UserOutlined, SettingOutlined, LogoutOutlined, BarsOutlined, BellOutlined} from '@ant-design/icons';
import '../../styles/style.css'
import './style.css'
import { connect } from 'react-redux';
import {logOut} from '../../action/loginAction';
import { Link } from 'react-router-dom';
import {Navbar, NavItem, Container, RNavbar} from './topbarStyle'
import moment from 'moment';
import { useLocation } from 'react-router-dom'

import {mainnotificationCount,mainnotificationList} from '../../action/support';

const menu  =  (api) => (
    <Menu style={{width: '200px', textAlign: 'center'}} >
      <Menu.Item onClick={() => history.push('/setting')}>
        <a rel="noopener noreferrer" href="#">
          <Avatar  shape="none" style={{margin: '0px', cursor: 'pointer', backgroundColor: '#fff', color: '#2C2E43'}} size={40} icon={<SettingOutlined />} /> {localStorage.getItem('lan') === 'bangla' ? 'সেটিং':'Setting'}
        </a>
      </Menu.Item>
      {/* <Menu.Item onClick={() => history.push('/Store')}>
        <a rel="noopener noreferrer" href="#">
          <Avatar  shape="none" style={{margin: '0px', cursor: 'pointer', backgroundColor: '#fff', color: '#2C2E43'}} size={40} icon={<CustomerServiceOutlined />} /> Store
        </a>
      </Menu.Item> */}
      <Menu.Item onClick={() => api() } >
        <a rel="noopener noreferrer" href="#" >
        <Avatar  shape="none" style={{margin: '0px', cursor: 'pointer', backgroundColor: '#fff', color: '#2C2E43'}} size={40} icon={<LogoutOutlined />} /> {localStorage.getItem('lan') === 'bangla' ? 'লগ আউট':'Logout'} </a>
      </Menu.Item>
      {/* <Menu.Item>
        <a rel="noopener noreferrer" href="http://www.tmall.com/">
          Logout
        </a>
      </Menu.Item> */}
    </Menu>
  );
  
const makeLocalTime = (date) => {
  let mainDate = date.split('T')[0];
  let hours = date.split('T')[1].split(':')[0];
  let minutes = date.split('T')[1].split(':')[1];
  var am = true;
  if (hours > 12) {
      am = false;
      hours -= 12
  } else if (hours == 12) {
      am = false;
  } else if (hours == 0) {
      hours = 12
  }
  return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
}
const t = (date) => {
  const utcTime = new Date(date);
const timeAgo = moment(utcTime).subtract(6, 'h').fromNow();
return timeAgo
}
const NotiDetails = (notification,getNotCount) => {
 
    // getNotCount()
  
  return(
    <Menu style={{minWidth: '350px', maxWidth: '400px', margin: 'auto', textAlign: 'left', maxHeight: '600px', minHeight: '100px', overflow: 'auto', padding: '10px'}}>
        {notification ? Object.keys(notification).length > 0 ? 
          notification?.map(item => (
          // console.log(moment(moment(item?.created_at)).fromNow())
            item?.created_at ?

              item?.seen ?
                <Menu.Item style={{backgroundColor: '#231a52'}}>
                  
                  <Comment
                    author={item?.seen ? null : <Tag color="#f50"><span style={{color: '#FFF'}}>New</span></Tag>}
                    content={
                      <p style={{color: '#FFF'}}>
                        {item?.message}
                      </p>
                    }
                    datetime={
                      <Tooltip title={makeLocalTime(item?.created_at)}>
                        <span style={{color: "#ada7a7"}}>{t(item?.created_at)}</span>
                      </Tooltip>
                    }
                  />
                </Menu.Item>
                :

                <Menu.Item style={{backgroundColor: '#372f5c'}}>
                  
                  <Comment
                    author={item?.merchant_seen ? <Tag color="#87d06834">Seen</Tag> : <Tag color="#f50"><span style={{color: '#FFF'}}>New</span></Tag>}
                    content={
                      <p style={{color: '#FFF'}}>
                        
                        {item?.message}
                      </p>
                    }
                    datetime={
                      <Tooltip title={makeLocalTime(item?.created_at)}>
                        <span style={{color: "#ada7a7"}}>{t(item?.created_at)}</span>
                      </Tooltip>
                    }
                  />
                </Menu.Item>

            :null
          ))
          :
          <svg class="ant-empty-img-default" width="184" height="152" viewBox="0 0 184 152" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g transform="translate(24 31.67)"><ellipse class="ant-empty-img-default-ellipse" cx="67.797" cy="106.89" rx="67.797" ry="12.668"></ellipse><path class="ant-empty-img-default-path-1" d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"></path><path class="ant-empty-img-default-path-2" d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z" transform="translate(13.56)"></path><path class="ant-empty-img-default-path-3" d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"></path><path class="ant-empty-img-default-path-4" d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"></path></g><path class="ant-empty-img-default-path-5" d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"></path><g class="ant-empty-img-default-g" transform="translate(149.65 15.383)"><ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse><path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path></g></g></svg>
        : null}
        
    </Menu>
  )
  
  };

const DropdownMenu = ({api}) => (
  
    <Dropdown key="more" overlay={menu(api)} style={{}}>
     
        <Avatar className='setting' size={40} icon={<UserOutlined />} />
    </Dropdown>
  )


const AuthTopBar = ({logOut,mainnotificationCount,mainnotificationList,counter}) => {
  const [show, setShow] = useState(false);
  
  const [notification, setNotification]   = useState(null);
  const [notCount, setNotCount]   = useState(0);
  const [lan, setLan]   = useState('english');
  const [width, setWidth]   = useState(0);
  const [height, setHeight] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const routers = useLocation()

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const updateDimensions = () => {
    
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  
  useEffect(() => {
    setLan(localStorage.getItem('lan') ? localStorage.getItem('lan') : 'english')
  }, [])
  const handleActive = (name) => {
    setShow(!show)
  
  }
  useEffect( async () => {
    const notCount = await mainnotificationCount();
  if(notCount) {
    setNotCount(notCount)
  }
    

    setWidth(window.innerWidth);
   
    window.addEventListener("resize", updateDimensions);
    window.addEventListener('scroll', handleScroll, { passive: true });

    
    return () => (
        window.removeEventListener("resize", updateDimensions),
        window.removeEventListener('scroll', handleScroll))
  }, []);

  useEffect(  () => {
    const interval = setInterval(async() => {
      
      const noti = await mainnotificationCount();
      setNotCount(noti)
    }, 30000);
    return () => clearInterval(interval);
  }, []);
  
  const getNotData = async () => {
    const noti = await mainnotificationList();
    setNotification(noti)
  }


  const getNotCount = async () => {
    const noti = await mainnotificationCount();
    setNotCount(noti)
  }

  const forBigger = () => (
    <>
        <Col sm={2}>
          <Link to="/"><img src='./images/logo.png' style={{cursor: 'pointer'}}  width={130} styles={{marginLeft: '20px'}} /></Link>
        </Col>
        <Col sm={2}></Col>
        
        <Col sm={18}>
            <Navbar>
            <Link to={"/"} className={routers.pathname === "/dashboard" ? "active-class" : ""} ><NavItem   >{lan === 'bangla' ? 'ড্যাসবোর্ড': 'Dashboard'}</NavItem></Link>
            <Link to={"/parcel"} className={routers.pathname === "/parcel" || routers.pathname.includes('/parcel') || routers.pathname.includes('/parcel-details') ? "active-class" : ""}><NavItem >{lan === 'bangla' ? 'পার্সেল' : 'Parcels'}</NavItem></Link>
            <Link to={"/transaction"} className={routers.pathname === "/transaction" ? "active-class" : ""}><NavItem>{lan === 'bangla' ? 'লেনদেন' : 'Transaction'}</NavItem></Link>
            <Link to={"/store"} className={routers.pathname.includes('/store')  ? "active-class" : ""}><NavItem >{lan === 'bangla' ? 'স্টোর' : 'Store'}</NavItem></Link>
            <Link to={"/support"} className={routers.pathname === "/support" || routers.pathname.includes('/ticket') ? "active-class" : ""}><NavItem >{lan === 'bangla' ? 'সাপোর্ট' : 'Support'} {counter ? <Tag color="#f50">{counter}</Tag> : null}</NavItem></Link>
            </Navbar>
        </Col>
        
        <Col sm={1}>
          {notCount ?
            <Dropdown trigger="click"  key="more" overlay={NotiDetails(notification,getNotCount)} onClick = { () => getNotData()} >
              <Badge size='' count={notCount} className='notification-bell'>
                <Avatar  shape="none" className='notification'size={40} icon={<BellOutlined />} />
              </Badge>
            </Dropdown>
            :
            
            <Dropdown trigger="click" key="more" overlay={NotiDetails(notification,getNotCount)} onClick = { () => getNotData()}  >
                <Avatar  shape="none" className='notification'size={40} icon={<BellOutlined />} />
              
            </Dropdown>
            }
        </Col>
        <Col sm={1}>
          <Dropdown  trigger="click" key="more" overlay={menu(logOut)} style={{}}>
        
          <Avatar className='setting' size={40} icon={<UserOutlined />} />
        </Dropdown>
        </Col>
    </>
  )
  const forSmaller = () => (
    <>
      <Col sm={21}>
        <Link to="/"><img src='./images/logo.png' style={{cursor: 'pointer'}}  width={120} /></Link>
      </Col>
      <Col sm={3} style={{display: 'flex', justifyContent: 'center'}}>
        {notCount ?
            <Dropdown  trigger="click"  key="more" overlay={NotiDetails(notification,getNotCount)} onClick = { () => getNotData()}  >
              <Badge size='' count={notCount}>
                <Avatar  shape="none" size={40} icon={<BellOutlined />} />
              </Badge>
            </Dropdown>
            :
            <Dropdown  trigger="click" key="more" overlay={NotiDetails(notification,getNotCount)} onClick = { () => getNotData()}  >
                <Avatar  shape="none" size={40} icon={<BellOutlined />} />
              
            </Dropdown>
        }
          <Avatar  shape="none" size={40} icon={<BarsOutlined />} onClick={handleActive}/>

      </Col>
    </>
  )

  const responsiveMenu = () => (
    <RNavbar show = {show}>
      <Link to={"/"} className={routers.pathname == "/dashboard" ? "active-class" : ""} onClick={() => setShow(false)}><NavItem >{lan === 'bangla' ? 'ড্যাসবোর্ড': 'Dashboard'}</NavItem></Link>
      <Link to={"/parcel"} className={routers.pathname == "/parcel" ? "active-class" : ""} onClick={() => setShow(false)}><NavItem >{lan === 'bangla' ? 'পার্সেল' : 'Parcel'}</NavItem></Link>
      <Link to={"/transaction"} className={routers.pathname == "/transaction" ? "active-class" : ""} onClick={() => setShow(false)}><NavItem>{lan === 'bangla' ? 'লেনদেন' : 'Transaction'}</NavItem></Link>
      <Link to={"/store"} className={routers.pathname == "/store" ? "active-class" : ""} onClick={() => setShow(false)}><NavItem >{lan === 'bangla' ? 'স্টোর' : 'Store'}</NavItem></Link>
      <Link to={"/setting"} className={routers.pathname == "/setting" ? "active-class" : ""} onClick={() => setShow(false)}><NavItem >{lan === 'bangla' ? 'সেটিং' : 'Setting'}</NavItem></Link>
      <Link to={"/support"} className={routers.pathname === "/support" ? "active-class" : ""} onClick={() => setShow(false)}><NavItem >{lan === 'bangla' ? 'সাপোর্ট' : 'Support'}{counter ? <Tag color="#f50">{counter}</Tag> : null}</NavItem></Link>
      <NavItem onClick={() => logOut()}>{lan === 'bangla' ? 'লগ আউট' : 'Logout'}</NavItem>
    </RNavbar>
  )
  return ( 
      <>
      <Container  height = {scrollPosition} >
        {width > 992 ? forBigger():forSmaller()}
      </Container>
      {responsiveMenu()}
      </>
    );
}
const mapStateToProps = (state) => {
  return {
    counter: state.helpers.counter,
  }
}
export default connect(mapStateToProps,{logOut,mainnotificationCount,mainnotificationList})(AuthTopBar);