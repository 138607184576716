import { Layout } from 'antd';
import { Content, Footer } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import MessageAlert from '../components/messageAlert';
import AuthTopBar from '../components/topbar/authTopbar';
import history from '../history';
import '../styles/style.css'
import Switcher from './switcher';
import Verification from './verification';
import Cong from './cong.PNG'
import BarLoader from "react-spinners/BarLoader";
import { css } from "@emotion/react";
import '../styles/default.css';
const override = css`
  width:100% ;
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const ModalContainer = styled.div`
    position: fixed;
    top:${(props=>props.status ? '0px' : '-6000px')};
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.425);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    z-index: 1000;
`;
const ModalWrapper = styled.div`
    border-radius: 5px;
    align-content: center;
    text-align: center;
    width: 750px;
    height: 580px;
    margin: auto;
    background-color: #fff;
    
    box-shadow:  0 2px 4px 0 rgba(7, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
    position: relative;
    top:${(props=>props.status ? '0%' : '-1000px')};
    z-index: 10000;
    padding: 20px;
    transition: 0.7s;
    
    h3{
        text-align: center;
        padding: 15px;
        color: #3AB569;
        font-size: 24px;
    }
    h4{
        text-align: center;
    }
    p{
        font-size: 14px;
        text-align: center;
    }
    @media only screen and (max-width: 950px) {
        width: 450px;
        
    }
    @media only screen and (max-width: 768px) {
        width: 400px;
    }
    @media only screen and (max-width: 600px) {
        width: 300px;
        height: auto;
    }
    
`;
const AhoyButton = styled.button`
    cursor: pointer;
    border: none;
    background-color: #3AB569;
    padding: 10px 12px;
    border-radius: 5px;
    color: #fff;
    &:focus{
        outline: none;
    }
    &:hover{
        
        box-shadow:  0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
        
    }
    @media only screen and (max-width: 950px) {
        padding: 5px 8px;
    }
`;
const MainLayout = ({validationError, success, error, loading, refresh}) => {

  const [lan, setLan] = useState("english");

  const [Next, setNext] = useState(false)
  useEffect( async () => {
    setLan(
      localStorage.getItem("lan") ? localStorage.getItem("lan") : "english"
    );
    if(localStorage.getItem('token') === null){
      localStorage.clear();
      history.push('/login')
    }
  }, [])
  
    return ( 
        <>
        {loading ?
        <BarLoader color="#b91e1e" loading={loading}  size={150} css={override} />
      :
          <p style={{margin:'0px',padding: '2px',height: '4px', backgroundColor: '#fff', position: 'fixed', width: '100%', zIndex:'100'}}> </p>
      }
        
        {success ? <MessageAlert type="success" value={success} /> : null}
          {error ? <MessageAlert type="error" value={error} /> : null}
          {validationError ? 
            validationError.map((item,id )=> (
              <MessageAlert type="error" value={item.message} />
            ))
          :
          null
          }
          <ModalContainer status={localStorage.getItem('unauth') ? true : false}>
                
              {Next ?
                <Verification status ={Next} />

                :
                <ModalWrapper status={localStorage.getItem('unauth') ? true : false} style={{height: 'auto', width:'550px', backgroundColor: '#F8F8F8', display: 'flex', justifyContent:'center', flexDirection: 'column'}}>
                <img src={Cong} alt="Welcome to Jatayat" style={{width: '200px',alignSelf: 'center'}} />
                <h4 style={{alignSelf: 'center', margin: '5px 0px'}}>Thanks for Registration with</h4>
                <h2 style={{alignSelf: 'center', margin: '5px 0px', color: '#3AB569'}}>Jatayat</h2>
                <br/>
                <AhoyButton style={{width: ' 200px',alignSelf: 'center'}} onClick ={() => {setNext (true)}}>Continue</AhoyButton>
              </ModalWrapper>
                
              }
            </ModalContainer> 
        {/* === Main Layout with bgc === */}
        <Layout className='site-layout'>

            {/* === Main content with fix height === */}
            <Content style={{minHeight: '100vh', maxHeight: 'auto'}}>
                {localStorage.getItem('token') ? <AuthTopBar /> : null }
                {/* {localStorage.getItem('token') ? <AuthTopBar /> : <UnauthTopbar /> } */}
                <Switcher />
            </Content>

            {/* === Footer === */}
            <Footer  > <p style={{textAlign: 'center'}}>Copyright ©  2022-2023 Jatayat. All rights reserved.</p></Footer>

        </Layout>
        </>
     );
}
const mapStateToProps = (state) => {
    return {
      loading: state.helpers.loading,
      success: state.helpers.success,
      error: state.helpers.error,
      validationError: state.helpers?.validationError,
    }
  }
export default connect(mapStateToProps)(MainLayout);