
import React, { useEffect, useState } from 'react';
import {
    Table,
    Tag,
    Button,
    Row,
    Col,
    Select,
    Form,
    Empty,
    PageHeader,
  } from "antd";
  
  import {
    DownCircleOutlined,
    UpCircleOutlined,
  } from "@ant-design/icons";
  import { connect } from "react-redux";
  import { fetchShops,fetchStore } from "../../action/shop";
  import {notificationCount} from "../../action/support";
  import { Container, FlexContainer } from "../../styles/globalStyle";
  import {getTransaction} from "../../action/paymentAction"
  import CreateStore from '../store/createStore';
  import AddPaymentMethod from '../store/addPaymentMethod';
  
const { Option } = Select;

const columns = [
    {
      title: "In/Out",
      dataIndex: "transaction_flow",
      key: "transaction_flow",
      width: "10%",
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: "10%"
    },
    {
      title: "Transaction Info",
      dataIndex: "transaction_info",
      key: "transaction_info",
      width: "40%",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "charges",
      width: "15%",
    }
  ];

  const makeLocalTime = (date) => {
    let mainDate = date.split("T")[0];
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      mainDate + " , " + hours + ":" + minutes + " " + (am ? "a.m" : "p.m")
    );
  };
const TransactionList = ({fetchShops, getTransaction,fetchStore,notificationCount}) => {
    const [shops, setShops] = useState(null)
    const [shop, setShop] = useState(null)
    const [total, setTotal] = useState("")
    const [lan, setLan] = useState("")
    const [shopId, setShopId] = useState(null)
    const [createForm, setCreateForm] = useState(false)
    const [createPaymentForm, setCreatePaymentForm] = useState(false)
    const [isValid, setIsValid] = useState(null)
    const [data, setData] = useState([])

    useEffect( async () => {
        window.scrollTo(0, 0);
        notificationCount()
        setLan(localStorage.getItem("lan")
        ? localStorage.getItem("lan")
        : "english")
        const res =  await getShops()
        
    if(localStorage.getItem('active_store')){
      
      let id= localStorage.getItem('active_store')
      
      const result = await fetchStore(id)
      setShop(result)
      if(result?.payment_info == null){
        
        setIsValid(false)
        setData([])
        setTotal("")
      }else{
        setIsValid(true)
        const res = await getTransaction(id);
        
        setData(res)
        setTotal(res?.length)
      }
    }
    else{
      localStorage.setItem('active_store',res[0].shop_id)
      localStorage.setItem('active_store_name',res[0].name)
      let id = res[0].shop_id
      const result = await fetchStore(id)
      if(result?.payment_info == null){
        setIsValid(false)
        setData([])
        setTotal("")
      }else{
        setIsValid(true)
        const res = await fetchStore(id);
        setData(res)
        setTotal(res?.length)
      }
      
    }
    }, [])
    const getShops = async () => {
        const res = await fetchShops()
        if(res){
            setShops(res)
        }
    }
    const handleShopSearch = async (e) => {
        localStorage.setItem('active_store', e)

        setShopId(e)
        const result = await fetchStore(e)
        setShop(result)
        localStorage.setItem('active_store_name', result?.name)

        if(result?.payment_info == null){
          setIsValid(false)
          setData([])
          setTotal("")
        }
        else{
          setIsValid(true)
          const res = await getTransaction(e)
          setData(res)
          setTotal(res?.length)
        }
    };
    const refreshPaymentStatus = async () => {
        const result = await fetchStore(shopId)
        if(result?.payment_info == null){
          setIsValid(false)
          setData([])
          setTotal("")
        }
        else{
          setIsValid(true)
          const res = await getTransaction(shopId)
          setData(res)
          setTotal(res?.length)
        }
    };

    const createOptions = (label, value) => {
        return { label, value };
      };
    const createData = (
        transaction_flow,
        transaction_id,
        transaction_info,
        amount
      ) => {
        return {
          transaction_flow,
          transaction_id,
          transaction_info,
          amount
        };
      };

    let option = [];
    option = shops
    ? shops.map((item) => createOptions(item.name, item.shop_id))
    : null;

    const rows = [
        data?.length > 0
          ? data?.map((data) =>
              createData(
                data?.transaction_flow?.shop === "Credit" ?
                <UpCircleOutlined style={{textAlign: 'center', color: 'green', fontSize: '34px'}} />
                :
                <DownCircleOutlined style={{textAlign: 'center', color: 'red', fontSize: '34px'}} />
                ,
                <>
                
                <p style={{color: 'gray', margin: '2px', fontSize: '12px'}}>{makeLocalTime(data?.created_at)}</p>
                <p style={{color: 'black', margin: '2px', fontSize: '12px', fontWeight: 'bold'}}>{data?.transaction_id}</p>
                <p style={{color: 'black', margin: '2px', fontSize: '12px', fontWeight: '400'}}>{data?.order?.order_id}</p>
                
                </>,
                data.is_service === true ? 
                data?.transaction_state !== "Returned" ? 
                  <>
                    <p style={{margin: '2px'}}>Collected Amount</p>
                    <p style={{margin: '2px'}}>Delivery Charge</p>
                    <p style={{margin: '2px'}}>COD</p>
                    <hr />
                    <p style={{margin: '2px'}}>Amount Added</p>
                </>
                :
                <>
                    <p style={{margin: '2px'}}>Return Charge</p>
                    <hr />
                    <p style={{margin: '2px'}}>Amount Added</p>
                </>
                :
                data?.transaction_flow?.shop === "Credit" ? 
                <>
                    <p style={{margin: '2px'}}>Cash Deposite</p>
                </>
                :
                <p style={{margin: '2px'}}>Cash Withdrawal</p>
                ,
                data.is_service === true ? 
                data?.transaction_state !== "Returned" ? 
                <>
                  <p style={{textAlign: 'right',margin: '2px'}}>{data?.collected_amount} BDT</p>
                  <p style={{textAlign: 'right',margin: '2px'}}>{data?.delivery_charge} BDT</p>
                  <p style={{textAlign: 'right',margin: '2px'}}>{data?.cod} BDT</p>
                  <hr />
                  <p style={{textAlign: 'right',margin: '2px'}}>{data?.merchant_payment} BDT</p>
                </>
                :
                <>
                  <p style={{textAlign: 'right',margin: '2px'}}>{-data?.merchant_payment} BDT</p>
                  <hr />
                  <p style={{textAlign: 'right',margin: '2px', color: 'red'}}>{data?.merchant_payment} BDT</p>
                </>
                :
                data?.transaction_flow?.shop === "Credit" ? 
                <>
                    <p style={{margin: '2px', textAlign: 'right'}}>{data.transfer_amount} BDT</p>
                </>
                :
                <p style={{margin: '2px', textAlign: 'right', color: 'red'}}>{data.transfer_amount} BDT</p>
               ,
              )
            )
          : "",
      ];
    const onCreate = () => {
        setCreateForm(true)
    }
    const onClose = () => {
        setCreateForm(false)
    }
    const handleRefresh = async () => {
        getShops()
    }
    const noStore = () => (
            shops.length === 0 ?
            <FlexContainer style={{ height: "50vh" }}>
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    alignSelf: "center",
                    fontWeight: "600",
                  }}
                >
                  {lan === "bangla"
                    ? "নতুন স্টোর তৈরি করুন"
                    : "Create Your new store"}
                </h1>
                <p style={{ textAlign: "center", color: "gray" }}>
                  {lan === "bangla"
                    ? "আপনার কোনো স্টোর এখনো নেই, পার্সেল এড কয়ার জন্য স্টোর তৈরি করুন"
                    : "You don't have store. Add store to create parcel"}
                </p>
                <Button
                  type="primary"
                  style={{ textAlign: "center" }}
                  onClick={onCreate}
                >
                  {lan === "bangla" ? "এড স্টোর" : "Add Store"}
                </Button>
              </div>
            </FlexContainer>
            :
            null
    )
    const shopForm = () => (
      <Form
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      autoComplete="off"
      requiredMark={false}
    >

      <Row gutter={16}>
        <Col xs={24} md={24}>
          <Form.Item
            label={
              lan == "bangla" ? "আপনার সপ সিলেক্ট করুন" : "Select Shop"
            }
            name="shop"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              onChange={(e) => handleShopSearch(e)}
            >
              {option.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      </Form>
    )
    const storeName = () => (
      localStorage.getItem('active_store_name')
      ?
      <Tag color="#231a52" style={{fontSize: '18px',padding: '5px', textAlign: 'center'}} >{lan === 'bangla'? `স্টোরের নাম - ${localStorage.getItem('active_store_name')}` : `Current Store - ${localStorage.getItem('active_store_name')}`}</Tag>
      :null
    )
    const paymentSection = () => (
      isValid == false ?
      <Tag onClick={() => setCreatePaymentForm(true)} color="red" style={{width: '100%', padding: '10px', fontWeight: '550', textAlign: 'center', fontSize:'16px', cursor: 'pointer'}}>{lan === 'bangla' ? '***স্টোরের পেমেন্ট এর পদ্ধতি যুক্ত করা হয় নি, পেমেন্ট পদ্ধতি যুক্ত করন***' : '***This shop has no payment method, please add payment method***'}</Tag>
      :null
    )
    const dataSection = () => (
      data.length > 0 ?   
      <Row >

        <Col xs={24}>
         <h3 style={{ float: 'right', padding: '0px 10px 10px 0px' }}>Account Balace: <strong>{shop?.account_balance} BDT</strong></h3>
        </Col>

        <Col xs={24}>
            
          <Table
            columns={columns}
            scroll={{ x: 10 }}
            dataSource={rows[0]}
          />
          
        </Col>
      </Row>
      : 
      shops.length === 0 ?
      null:
      <Empty />
      // null
    )

    const pageHeader = () => (

      <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="All Transaction"
      extra={
        <>
          {/* <Button
            type="primary"
            icon={<MailOutlined />}
            size="middle"
            onClick={() => this.setState({ isModalVisible: true })}
          >
            {" "}
            Send Notification
          </Button> */}
           
          {total !== 0 && total !== '' ? (
            <Tag
              style={{
                fontSize: "14px",
                padding: "5px",
                float: "right",
              }}
              color="#87d068"
            >
              {lan == "bangla"
                ? `${total} টি ফলাফল পাওয়া গিয়েছে`
                : `Total ${total} data found`}
            </Tag>
          ) : (
            total !== '' ?
            <Tag
              style={{
                fontSize: "14px",
                padding: "5px",
                float: "right",
              }}
              color="#ce821f"
            >
              {lan == "bangla"
                ? `কোনো ফলাফল পাওয়া যায় নি`
                : `No data found`}
            </Tag>
            :
            null
          )}
        </>
      }
    ></PageHeader>
    )
    return ( 
        <Container>
          {shops !== null ? 
           
            <>
              {pageHeader()}
              {shopForm()}
              {storeName()}
              <br />
              {paymentSection()}
              {dataSection()}
              {noStore()}
             
            </>
           : (
            null
            
            
          )}
          <CreateStore status={createForm} close={onClose} refresh = {handleRefresh} re={true} />
          <AddPaymentMethod shop_id={shopId} refresh={refreshPaymentStatus} status={createPaymentForm} close = {() => setCreatePaymentForm(false)} />

        </Container>
         );
}
 
export default connect(null,{fetchShops,fetchStore, getTransaction,notificationCount})(TransactionList);